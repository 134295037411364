import React from 'react';

const SvgUniquePaymentWarning = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={112} height={112} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={56} cy={56} r={32} fill="#FFF8DA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.5 40a2 2 0 00-2 2v20a2 2 0 002 2h3a2 2 0 002-2V42a2 2 0 00-2-2h-3zm0 26a2 2 0 00-2 2v3a2 2 0 002 2h3a2 2 0 002-2v-3a2 2 0 00-2-2h-3zM98.1 55.636c0-.7.55-1.267 1.227-1.267h10.719c.677 0 1.227.567 1.227 1.267s-.55 1.268-1.227 1.268H99.327c-.678 0-1.227-.568-1.227-1.268zm-2.1-8.02c0-.517.31-1.003.807-1.19l7.213-2.713c.637-.24 1.341.1 1.573.758.232.658-.097 1.385-.733 1.625l-7.213 2.712c-.637.24-1.341-.1-1.573-.758a1.305 1.305 0 01-.074-.433zm.074 15.607c-.05.143-.074.289-.074.433 0 .518.31 1.004.807 1.192l7.213 2.711c.636.24 1.34-.1 1.572-.757.231-.658-.096-1.385-.733-1.625l-7.212-2.712c-.637-.24-1.341.1-1.573.758zM13.903 55.636c0-.7-.55-1.267-1.228-1.267H1.957c-.678 0-1.227.567-1.227 1.267s.55 1.268 1.227 1.268h10.718c.678 0 1.228-.568 1.228-1.268zm2.1-8.02c0-.517-.31-1.003-.808-1.19l-7.213-2.713c-.637-.24-1.34.1-1.573.758-.231.658.097 1.385.734 1.625l7.213 2.712c.636.24 1.34-.1 1.572-.758.05-.143.075-.29.075-.433zm-.075 15.607c.05.143.075.289.075.433 0 .518-.31 1.004-.808 1.192l-7.212 2.711c-.636.24-1.34-.1-1.572-.757-.23-.658.096-1.385.733-1.625l7.212-2.712c.636-.24 1.34.1 1.572.758z"
      fill="#FCCE01"
    />
  </svg>
);

export default SvgUniquePaymentWarning;
