import React from 'react';

const SvgLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 33" {...props}>
    <path
      fill="#FCCE01"
      fillRule="evenodd"
      d="M24 2a16.5 16.5 0 016.89 6.75c.1.2-.19.36-.32.18q-1.1-1.5-2.47-2.7a17.6 17.6 0 00-10.02-4.25 11 11 0 00-5.04.55c-1.5.61-3.47 2.06-3.41 4.13.1 3.58 3.33 4.9 7 6.42l1.81.74c1.38.56 3.25 1.31 5 2.29a12 12 0 013.85 3.17 7 7 0 011.09 3.74v.02c-.01 5.07-5.64 9.17-12.57 9.17-2.96 0-5.67-.74-7.82-1.99a16.5 16.5 0 01-6.88-6.75c-.1-.2.18-.37.32-.19q1.1 1.5 2.47 2.7a17.6 17.6 0 0010.02 4.26c1.96.14 3.35.05 5.04-.56 1.5-.6 3.47-2.06 3.41-4.12-.1-3.59-3.33-4.92-7-6.42l-1.82-.75a44 44 0 01-4.99-2.28 12 12 0 01-3.85-3.18A7 7 0 013.62 9.2v-.01C3.63 4.1 9.26 0 16.2 0c2.96 0 5.67.74 7.82 2m1.5 5c-2.51-1.44-5.08-2.35-7.79-2.2q-.6.06-1.14.24c-1.1.35-1.84 1.06-1.86 2.01s.78 1.78 1.99 2.21l.72.28q2.66.89 5.18 2.1c4.37 2.18 8.86 5.88 7.43 11.6-.04.17.2.25.29.1 3.74-7.64.8-12.82-4.82-16.34M6.49 25.21c2.51 1.44 5.08 2.35 7.79 2.19q.6-.05 1.14-.24c1.1-.35 1.84-1.05 1.86-2 .02-.94-.78-1.78-1.99-2.22l-.72-.28q-2.66-.89-5.18-2.1C5.02 18.38.53 14.68 1.96 8.96c.04-.16-.2-.25-.29-.09C-2.07 16.5.87 21.7 6.5 25.2"
      clipRule="evenodd"
    />
  </svg>
);

export default SvgLogo;
