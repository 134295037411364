import React from 'react';

const SvgUniquePaymentSuccess = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={113} height={112} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={56.5} cy={56} r={32} fill="#FFF8DA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.995 52.82l-.951.951a2.538 2.538 0 00-.005 3.586l8.92 8.904a2.526 2.526 0 001.794.739 2.582 2.582 0 001.8-.753l16.5-16.167c1-.998 1-2.606.009-3.594l-.958-.954a2.557 2.557 0 00-3.6.008L52.75 58.968l-6.164-6.155a2.547 2.547 0 00-3.592.008zM98.6 55.636c0-.7.55-1.267 1.227-1.267h10.719c.677 0 1.227.567 1.227 1.267s-.55 1.268-1.227 1.268H99.827c-.678 0-1.227-.568-1.227-1.268zm-2.1-8.02c0-.517.31-1.003.807-1.19l7.213-2.713c.637-.24 1.341.1 1.573.758.232.658-.097 1.385-.733 1.625l-7.213 2.712c-.637.24-1.341-.1-1.573-.758a1.305 1.305 0 01-.074-.433zm.074 15.607c-.05.143-.074.289-.074.433 0 .518.31 1.004.807 1.192l7.213 2.711c.636.24 1.34-.1 1.572-.757.231-.658-.096-1.385-.733-1.625l-7.212-2.712c-.637-.24-1.341.1-1.573.758zM14.403 55.636c0-.7-.55-1.267-1.228-1.267H2.457c-.678 0-1.227.567-1.227 1.267s.55 1.268 1.227 1.268h10.718c.678 0 1.228-.568 1.228-1.268zm2.1-8.02c0-.517-.31-1.003-.808-1.19l-7.213-2.713c-.637-.24-1.34.1-1.573.758-.231.658.097 1.385.734 1.625l7.213 2.712c.636.24 1.34-.1 1.572-.758.05-.143.075-.29.075-.433zm-.075 15.607c.05.143.075.289.075.433 0 .518-.31 1.004-.808 1.192l-7.212 2.711c-.636.24-1.34-.1-1.572-.757-.23-.658.096-1.385.733-1.625l7.212-2.712c.636-.24 1.34.1 1.572.758z"
      fill="#FCCE01"
    />
  </svg>
);

export default SvgUniquePaymentSuccess;
