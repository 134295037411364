import React from 'react';

const SvgUniquePaymentConfirmIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.554 4.89a1.521 1.521 0 00-1.071-.446l-6.836.001-.5 1.138h6.882a.79.79 0 01.791.783v7.493a.79.79 0 01-.791.783H7.166l-.519 1.18h11.836A1.52 1.52 0 0020 14.305V5.96a1.521 1.521 0 00-.446-1.071zM6.15 13.37a.115.115 0 00-.115-.116H1.798a.115.115 0 00-.115.115v.692a.116.116 0 00.115.115h4.236a.115.115 0 00.115-.115v-.692zm.1-3.502a.115.115 0 00-.115-.115H.116A.116.116 0 000 9.867v.69a.115.115 0 00.115.116h6.019a.115.115 0 00.115-.115v-.691zm3.626-3.526a.115.115 0 00-.115-.115H3.22a.115.115 0 00-.115.115v.691a.115.115 0 00.115.116h6.54a.115.115 0 00.115-.115v-.692zm6.501.083h-5.599l-3.204 7.29h8.803a1.348 1.348 0 011.348-1.348V7.774a1.348 1.348 0 01-1.348-1.349zm-3.352 5.592v.64h-.387v-.62c-.357-.007-.726-.118-.941-.271l.148-.412c.263.17.57.262.885.264.436 0 .731-.252.731-.603 0-.338-.24-.547-.695-.731-.626-.246-1.014-.53-1.014-1.064 0-.51.363-.897.929-.99v-.62h.38v.596c.37.012.621.11.8.215l-.154.406a1.485 1.485 0 00-.774-.21c-.474 0-.652.283-.652.53 0 .319.227.48.762.7.633.258.953.578.953 1.125 0 .486-.338.94-.971 1.045z"
      fill="#38B2A6"
    />
  </svg>
);

export default SvgUniquePaymentConfirmIcon;
