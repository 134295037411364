import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { i18n } from '~i18n';
import LanguageTips from './index';

export const LanguageTipsContext = React.createContext<any>(null);

const LANGUAGES_CODE = ['en', 'es', 'ca', 'de', 'zh'];
const SUPPORT_LANGUAGES = ['en-gb', 'es', 'ca', 'de', 'zh-cn'];

const LANGUAGE_HINT = 'LANGUAGE_HINT';

const LanguageTipsProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const { pathname } = router;

  const hideLanguageTipsRouter = ['/authLogin'];

  const [hintLanguage, setHintLanguage] = useState('');
  const localeLanguage = i18n?.language ? i18n?.language?.split('-')[0] : 'en';

  useEffect(() => {
    const browserLanguage = window?.navigator?.language?.toLowerCase();

    if (!browserLanguage || pathname.includes('unique-payment')) return;
    const browserLanguageCode = browserLanguage.split('-')[0];

    const languageSupport = LANGUAGES_CODE.includes(browserLanguageCode);

    if (
      languageSupport &&
      localeLanguage !== browserLanguageCode &&
      !sessionStorage.getItem(LANGUAGE_HINT)
    ) {
      const lang = SUPPORT_LANGUAGES.find(item => item?.indexOf(browserLanguageCode) > -1) || '';
      setHintLanguage(lang);
    }
  }, [localeLanguage, pathname]);

  const handleClose = () => {
    sessionStorage.setItem(LANGUAGE_HINT, '1');
    setHintLanguage('');
  };

  const handleConfirm = () => {
    sessionStorage.setItem(LANGUAGE_HINT, '1');
    if (hintLanguage && router.asPath) {
      window.location.href = router.asPath.replace(i18n.language, hintLanguage);
    }
  };
  return (
    <LanguageTipsContext.Provider value={{}}>
      {!!hintLanguage && !hideLanguageTipsRouter.includes(pathname) && (
        <LanguageTips
          open={!!hintLanguage}
          language={hintLanguage}
          onClose={() => handleClose()}
          onConfirm={() => handleConfirm()}
        />
      )}
      {children}
    </LanguageTipsContext.Provider>
  );
};

export default LanguageTipsProvider;
